import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';
import { THE_BRIDE } from '@/constants';

import txtWording from './locales';

function ModalAddress({ onClose, visible, onCopyText, lang }) {
  const address = `Alivia Residence Blok A15, Gedawang, Banyumanik, Kota Semarang 50266`;
  const fullName = `${THE_BRIDE}`;

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" fontSize="lg" color="secondaryColorText" marginTop="0" fontWeight="bold">
          {fullName}
        </Text>
        <Text textAlign="center" color="black" fontSize="md">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="secondaryColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(address, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopyAddress[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton marginTop="12px" {...BUTTON_PROPS} />
        <DrawerHeader
          borderBottomWidth="1px"
          fontSize="2xl"
          color="secondaryColorText"
          fontFamily="cursive"
          fontWeight="normal"
        >
          {txtWording.sendGift[lang]}
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDescAddress[lang]}
          </Text>
          {renderBank()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;