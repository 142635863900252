import React, { useState } from 'react';
import { string } from 'prop-types';

import { Box, Text, Heading, Center, Button, useToast } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import txtWording from './locales';

import { BG_PRIMARY_GRADIENT, BUTTON_PROPS } from '@/constants/colors';
import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
} from '@/constants/feature-flags';
import useLang from '@hooks/useLang';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

const DEFAULT_BOTTOM = {
  bgColor: 'bgSecondary',
  color: 'alternativeDark',
  marginTop: '12px',
  boxShadow: 'xl',
  ...BUTTON_PROPS,
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();
  const lang = useLang();

  const onCopyText = async (text) => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary" padding="32px" {...rest}>
      <Box
        boxShadow="2xl"
        bgColor="bgPrimary"
        padding="8px"
        bgSize="initial"
        width="100%"
        backgroundImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${IMG_PATTERN_SQUARE})`}
        border="6px solid"
        borderColor="bgSecondary"
        borderRadius="24px"
      >
        <Box padding="52px 32px">
          <Center>
            <WithAnimation>
              <Heading size="2xl" color="mainColorText" fontWeight="normal">
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text color="mainColorText" align="center" margin="16px 0">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center>
              <WithAnimation>
                <Button
                  size="sm"
                  bgColor="alternativeDark"
                  {...DEFAULT_BOTTOM}
                  onClick={() => setModalState(MODAL_STATE.bank)}
                >
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {/* Send gift button section */}
          {ENABLE_SEND_GIFT && (
            <Center>
              <WithAnimation>
                <Button
                  size="sm"
                  bgColor="alternativeDark"
                  {...DEFAULT_BOTTOM}
                  onClick={() => setModalState(MODAL_STATE.address)}
                >
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center>
              <WithAnimation>
                <Button
                  size="sm"
                  bgColor="alternativeDark"
                  {...DEFAULT_BOTTOM}
                  onClick={() => setModalState(MODAL_STATE.confirm)}
                >
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        isOpen={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: 'en',
};

export default React.memo(GiftCardSection);