import React from 'react';
import { bool, func, string } from 'prop-types';
import Bank from '@invitato/helpers/dist/constants/banks';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Image,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';
import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="secondaryColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" {...BUTTON_PROPS} />
        <DrawerHeader
          borderBottomWidth="1px"
          fontSize="2xl"
          color="secondaryColorText"
          fontFamily="cursive"
          fontWeight="normal"
        >
          Bank Transfer
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontFamily="normal" fontSize="sm" margin="16px 0" color="black">
            {txtWording.modalDesc[lang]}
          </Text>
          {renderBank(Bank.bni, 'Indah Wahyu Sulistyowati', '0350406422')}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;